import { fetcher } from '../fetcher';

const EVENTS_ENDPOINT = '/events';

export const eventsService = {
  getInformationc: async () => {
    return await fetcher(`${EVENTS_ENDPOINT}/informations`, {
      method: 'GET',
      schema: informationsSchema,
    });
  },
  getJobOffers: async () => {
    return await fetcher(`${EVENTS_ENDPOINT}/job-offers`, {
      method: 'GET',
      schema: jobOffersSchema,
    });
  },
  getNews: async () => {
    return await fetcher(`${EVENTS_ENDPOINT}/news`, {
      method: 'GET',
      schema: newsArraySchema,
    });
  },
  readNotification: async (payload: NotificationReadPayload) => {
    return await fetcher(`${EVENTS_ENDPOINT}/notifications/read`, {
      method: 'POST',
      body: payload,
    });
  },
  getNotifications: async () => {
    return await fetcher(`${EVENTS_ENDPOINT}/notifications`, {
      method: 'GET',
      schema: notificationsSchema,
    });
  },
  deleteNotification: async (payload: NotificationReadPayload) => {
    return await fetcher(`${EVENTS_ENDPOINT}/notifications`, {
      method: 'DELETE',
      body: payload,
    });
  },
  getSchedules: async () => {
    return await fetcher(`${EVENTS_ENDPOINT}/schedules`, {
      method: 'GET',
      schema: scheludesSchema,
    });
  },
  readEvent: async ({
    type,
    id,
  }: {
    type: 'information' | 'offer' | 'news';
    id: number;
  }) => {
    return await fetcher(`/event/${type}/${id}/read`, {
      method: 'POST',
    });
  },
};
