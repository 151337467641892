import { ref, provide, inject } from 'vue';

const isOpenSymbol = Symbol('isSidemenuOpen');

export function useSidemenuProvider() {
  const isSidemenuOpen = ref(true);

  provide(isOpenSymbol, isSidemenuOpen);

  return { isSidemenuOpen };
}

export function useSidemenuInjector() {
  const isSidemenuOpen = inject<Ref<boolean>>(isOpenSymbol);

  if (!isSidemenuOpen) {
    throw new Error(
      'useSidemenuInjector must be used after useSidemenuProvider',
    );
  }

  return {
    isSidemenuOpen,
  };
}
